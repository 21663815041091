import * as React from 'react';
import Logo from './components/Logo/Logo';
import Box from '@mui/material/Box';

export default function MyApp() {
  return (
    <div>
      <Box sx={{pl:8, pt: 1}}><Logo/></Box>
    </div>
  );
}
